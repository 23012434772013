<div class="service-details">
  <h3 class="title">Duplicata & remplacement du permis de conduire</h3>

  <div class="card border mt-4">
    <div class="card-body">
      <div class="bg-light p-3 rounded">
        <div class="row">
          <div class="col-4 col-md mb-3 mb-md-0">
            <app-number-box [number]="1" [filled]="pageActive(1)">
              Infos sur la demande
            </app-number-box>
          </div>

          <div class="col-4 col-md mb-3 mb-md-0">
            <app-number-box [number]="2" [filled]="pageActive(2)">
              Récapitulatif
            </app-number-box>
          </div>
          <div class="col-4 col-md mb-3 mb-md-0">
            <app-number-box [number]="3" [filled]="pageActive(3)"
              >{{ "Payment" | translate }}
            </app-number-box>
          </div>
        </div>
      </div>
    </div>

    <div id="duplicata-permis-form" class="card-body">
      <div class="nav-block-page" [class.show]="page == 1">
        <div class="row">
          <div class="col">
            <h6>Sélectionner (duplicata/remplacement)</h6>
            <div class="mb-3">
              <div class="input-styled">
                <select
                  class="form-select form-select-lg"
                  name="type"
                  [(ngModel)]="form.type"
                >
                  <option [value]="''" selected>Sélectionner une option</option>
                  <option value="duplicata">
                    Duplicata du permis de conduire
                  </option>
                  <option value="remplacement">
                    Remplacement du permis de conduire
                  </option>
                </select>
              </div>
              <div class="text-feedback"></div>
            </div>
          </div>
          <div class="col-md-6">
            <h6>Numéro du permis de conduire</h6>

            <div class="mb-3">
              <div class="input-styled">
                <input
                  type="text"
                  class="form-control"
                  name="num_permis"
                  [(ngModel)]="form.num_permis"
                />
              </div>
              <div class="text-feedback"></div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <h6>Adresse e-mail</h6>
            <p class="small text-muted">
              Votre demande sera envoyée sur cette adresse mail
            </p>
            <div class="mb-3">
              <div class="input-styled">
                <input
                  type="email"
                  class="form-control"
                  name="email"
                  [(ngModel)]="form.email"
                  data-tr-rules="required|email|max:100"
                  data-tr-messages="Ce champ est obligatoire | Ce champ doit être une adresse e-mail valide | L'adresse e-mail trop longue"
                />
              </div>
              <div class="text-feedback"></div>
            </div>
          </div>
          <div class="col-md-6">
            <h6>Numéro de téléphone</h6>
            <p class="small text-muted">Un numéro de téléphone joignable</p>
            <div class="mb-3">
              <div class="input-styled">
                <input
                  type="text"
                  class="form-control"
                  name="phone"
                  [(ngModel)]="form.phone"
                />
              </div>
              <div class="text-feedback"></div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <h6>Lieu de retrait</h6>
            <div class="mb-3">
              <div class="input-styled">
                <select
                  class="form-select form-select-lg"
                  name="annexe_id"
                  [(ngModel)]="form.annexe_id"
                  (change)="selectAnnexe($event.target)"
                  data-tr-rules="required|int|min:1"
                  data-tr-messages="Ce champ est requis|''|Veuillez sélectionner un lieu de retrait"
                >
                  <option [value]="0" selected>Sélectionner un lieu</option>
                  <option [value]="annexe.id" *ngFor="let annexe of annexes">
                    {{ annexe.name }}
                  </option>
                </select>
              </div>
              <div class="text-feedback"></div>
            </div>
          </div>
          <div class="col-md-6">
            <h6>Groupe sanguin</h6>
            <div class="mb-3">
              <div class="input-styled">
                <select
                  class="form-select form-select-lg"
                  name="groupe_sanguin"
                  data-tr-rules="required"
                  data-tr-messages="Veuillez sélectionnez votre group sanguin"
                  [(ngModel)]="form.group_sanguin"
                >
                  <option [value]="''" selected>
                    Sélectionner un groupe ...
                  </option>
                  <option *ngFor="let group of groups">
                    {{ group }}
                  </option>
                </select>
              </div>
              <div class="text-feedback"></div>
            </div>
          </div>
        </div>
        <ng-container *ngIf="form.type">
          <div class="col-md-6">
            <div class="mb-3">
              <h6>
                <ng-container *ngIf="form.type == 'duplicata'">
                  Certificat de perte
                </ng-container>
                <ng-container *ngIf="form.type == 'remplacement'">
                  Copie de l'ancien permis
                </ng-container>
              </h6>
              <ng-container *ngIf="!form.file">
                <app-input-file
                  accept=".png,.jpg"
                  (changeEvent)="onFilePermisPrealableChange($event)"
                  [rules]="fileRules"
                  (_validate)="validateFile($event)"
                />
              </ng-container>
              <ng-container *ngIf="form.file">
                <app-input-file
                  accept=".png,.jpg"
                  (changeEvent)="onFilePermisPrealableChange($event)"
                  [selectedImage]="asset(form.file)"
                  placeholder="Modifier l'image existante"
                  [rules]="fileRules"
                  (_validate)="validateFile($event)"
                />
              </ng-container>
            </div>
          </div>
        </ng-container>
        <ng-container *ngIf="!form.type">
          <div class="col-md">
            <div class="alert alert-danger" role="alert">
              <strong>Attention</strong>
              <p>
                Vous devez sélectionner une option (Duplicata ou Remplacement)
                du permis de conduire avant de faire la demande.
              </p>
            </div>
          </div>
        </ng-container>
        <div class="col-md-3">
          <button
            class="btn btn-between btn-primary"
            (click)="gotoPage(2)"
            [disabled]="!formValid || !fileValid"
          >
            Suivant <app-icon icon="arrow-right"></app-icon>
          </button>
        </div>
      </div>

      <div class="nav-block-page" [class.show]="page == 2">
        <div class="row align-items-center mb-3">
          <div class="col-6">
            <h6 class="text-primary">Option choisie</h6>
            <p>
              <ng-container *ngIf="form.type == 'duplicata'">
                Duplicata du permis de conduire
              </ng-container>
              <ng-container *ngIf="form.type == 'remplacement'">
                Remplacement du permis de conduire
              </ng-container>
            </p>
          </div>
          <div class="col-6 text-end">
            <span (click)="gotoPage(1)" role="button">Modifier</span>
          </div>
        </div>
        <div class="row align-items-center mb-3">
          <div class="col-6">
            <h6 class="text-primary">Adresse e-mail</h6>
            <p>{{ form.email }}</p>
          </div>
          <div class="col-6 text-end">
            <span (click)="gotoPage(1)" role="button">Modifier</span>
          </div>
        </div>
        <div class="row align-items-center mb-3">
          <div class="col-6">
            <h6 class="text-primary">Numéro de téléphone</h6>
            <p>{{ form.phone }}</p>
          </div>
          <div class="col-6 text-end">
            <span (click)="gotoPage(1)" role="button">Modifier</span>
          </div>
        </div>

        <div class="row align-items-center mb-3">
          <div class="col-6">
            <h6 class="text-primary">Numéro du permis de conduire</h6>
            <p>{{ form.num_permis }}</p>
          </div>
          <div class="col-6 text-end">
            <span (click)="gotoPage(1)" role="button">Modifier</span>
          </div>
        </div>

        <div class="row align-items-center mb-3">
          <div class="col-6">
            <h6 class="text-primary">Lieu de retrait</h6>
            <p>{{ annexe }}</p>
          </div>
          <div class="col-6 text-end">
            <span (click)="gotoPage(1)" role="button">Modifier</span>
          </div>
        </div>

        <div class="row align-items-center mb-3">
          <div class="col-6">
            <h6 class="text-primary">Groupe sanguin</h6>
            <p>{{ form.group_sanguin }}</p>
          </div>
          <div class="col-6 text-end">
            <span (click)="gotoPage(1)" role="button">Modifier</span>
          </div>
        </div>
        <div class="row mb-3">
          <div class="col-6">
            <div class="col-6" *ngIf="copiePermis || form.file">
              <h6 class="mb-2">Fichier joint</h6>
              <div class="preview-pdf" (click)="openImageModal()">
                <div class="prev-eye col-md-9 col-xl-7">
                  <div class="overlay"></div>
                  <app-icon icon="eye" (click)="openImageModal()"></app-icon>
                  <img
                    [src]="imageSrc"
                    alt="Aperçu de l'image"
                    class="img-fluid"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="col-6 text-end">
            <span (click)="gotoPage(1)" role="button">Modifier</span>
          </div>
        </div>

        <div class="d-flex justify-content-between">
          <p>
            <button
              class="btn btn-between btn-outline-primary"
              (click)="gotoPage(1)"
            >
              <app-icon icon="arrow-left"></app-icon> Précédent
            </button>
          </p>
          <p>
            <button
              class="btn btn-between btn-primary"
              [disabled]="!formValid || !fileValid"
              (click)="save()"
            >
              <ng-container *ngIf="!rejetId">
                Payer et soumettre <app-icon icon="arrow-right"></app-icon>
              </ng-container>
              <ng-container *ngIf="rejetId">
                Soumettre <app-icon icon="arrow-right"></app-icon>
              </ng-container>
            </button>
          </p>
        </div>
      </div>

      <div class="nav-block-page" [class.show]="page == 3">
        <div class="card border mb-3 alert alert-primary" *ngIf="!rejetId">
          <div class="card-body p-2">
            <div class="row h-100">
              <div class="col h-100">
                <h4 class="mt-3">Paiement effectué avec succès</h4>
                <div class="border rounded-3 p-2">
                  <div class="row">
                    <div class="col">
                      <div class="mb-3">
                        <div class="fw-semibold">NPI</div>
                        <span class="text-uppercase">
                          {{ candidat?.npi }}
                        </span>
                      </div>
                      <div class="mb-3">
                        <div class="fw-semibold">Nom</div>
                        <span class="text-uppercase">
                          {{ candidat?.nom }}
                        </span>
                      </div>
                      <div class="mb-3">
                        <div class="fw-semibold">Prénoms</div>
                        <span class="text-uppercase">
                          {{ candidat?.prenoms }}
                        </span>
                      </div>
                    </div>
                    <div class="col">
                      <div class="mb-3">
                        <div class="fw-semibold">Montant payé</div>
                        <span class="text-uppercase">
                          {{ transaction?.montant }}
                        </span>
                      </div>

                      <div class="mb-3">
                        <div class="fw-semibold">Numéro de paiement</div>
                        <span class="text-uppercase">
                          {{ transaction?.phone }}
                        </span>
                      </div>
                      <div class="mb-3">
                        <div class="fw-semibold">Date de paiement</div>
                        <span class="text-uppercase">
                          {{
                            transaction?.date_payment
                              | date : "dd MMMM yyyy" : "" : "fr"
                          }}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <p class="my-3" *ngIf="download_url">
                  <a
                    type="button"
                    [href]="download_url"
                    class="btn btn-outline-primary w-100"
                    target="_blank"
                    >Télécharger la facture</a
                  >
                </p>
              </div>
            </div>
          </div>
        </div>

        <ng-container *ngIf="rejetId">
          <div class="alert alert-primary text-center" role="alert">
            <h6>
              Votre correction a été soumise avec succès <br />
              et en attente de validation par l'ANaTT.
            </h6>
            <p class="mt-3">
              <a href="/services/suivre-dossier" class="btn btn-primary">
                Suivre ma demande
              </a>
            </p>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</div>

<div
  class="modal fade"
  id="openImageModal"
  tabindex="-1"
  role="dialog"
  aria-hidden="true"
>
  <div
    class="modal-dialog modal-dialog-scrollable modal-dialog-centered"
    role="document"
  >
    <div class="modal-content">
      <div class="modal-body">
        <img [src]="imageSrc" class="img-fluid rounded-top" />
      </div>
    </div>
  </div>
</div>
