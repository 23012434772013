<div class="dropdown" dropdown>
  <span
    class="dropdown-btn"
    type="button"
    [id]="triggerId"
    data-bs-toggle="dropdown"
    aria-haspopup="true"
    aria-expanded="false"
  >
    <ng-content select="[dropdown-btn]"></ng-content>
  </span>
  <div class="dropdown-menu" [attr.aria-labelledby]="triggerId">
    <ng-content></ng-content>
  </div>
</div>
