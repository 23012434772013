<div class="alert-box bottom-right" style="display: none" id="alert-global">
  <div class="alert-content">
    <div class="alert-body">
      <span class="close-btn">
        <app-icon icon="x" [size]="14"></app-icon>
      </span>
      <div class="mt-3">
        <div class="alert-icon">
          <app-icon
            icon="check-circle"
            [size]="30"
            classe="text-success"
          ></app-icon>
          <app-icon
            icon="exclamation-triange"
            [size]="30"
            classe="text-warning"
          ></app-icon>
          <app-icon
            icon="exclamation-octagon"
            [size]="30"
            classe="text-danger"
          ></app-icon>
        </div>
        <div class="alert-message text-center mt-3"></div>
      </div>
    </div>
  </div>
</div>
