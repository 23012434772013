<div class="col-md-11 mx-auto">
  <h3 class="title">Demande de permis de conduire numérique</h3>
  <div class="card border" *ngIf="currentPage !== 'completed'">
    <div class="bg-light p-3 m-2 rounded">
      <div class="row">
        <div
          class="col-6 col-md mb-3 mb-md-0"
          *ngFor="let p of pages; let i = index"
        >
          <app-number-box [number]="i + 1" [filled]="p.active">
            {{ p.name }}
          </app-number-box>
        </div>
      </div>
    </div>
    <!--Infos sur la demande-->
    <form method="post" id="infos-demande">
      <div class="card-body" *ngIf="currentPage === 'infos-demande'">
        <div class="row">
          <div class="col-md-6">
            <h6>Adresse email</h6>
            <div class="mb-3">
              <div class="input-styled">
                <input
                  class="form-select"
                  name="email"
                  type="text"
                  qv-rules="required"
                  [(ngModel)]="email"
                  qv-messages="Ce champ est obligatoire"
                />
              </div>
              <div qv-feedback="email"></div>
            </div>
          </div>
          <div class="col-md-6">
            <h6>Catégorie de permis</h6>
            <div class="mb-3">
              <div class="input-styled">
                <select
                  class="form-select"
                  name="categorypermis"
                  [(ngModel)]="categorypermis"
                  qv-rules="required"
                  qv-messages="Ce champ est obligatoire"
                  (change)="selectPermis(categorypermis)"
                >
                  <option value="" selected>
                    Cliquer pour choisir une catégorie de permis
                  </option>
                  <option
                    *ngFor="let categorypermis of categoriespermis"
                    value="{{ categorypermis?.categorie_permis?.id }}"
                  >
                    Permis {{ categorypermis?.categorie_permis?.name }}
                  </option>
                </select>
              </div>
              <div qv-feedback="categorypermis"></div>
            </div>
          </div>

          <div class="col-md-6 mt-2">
            <button
              class="btn btn-between btn-primary"
              (click)="gotoPage('recapitulatif', $event)"
              [disabled]="!infosDemandeIsValid()"
            >
              Suivant <app-icon icon="arrow-right"></app-icon>
            </button>
          </div>
        </div>
      </div>
    </form>
    <!-- recapitulatif -->
    <div class="card-body p-2" *ngIf="currentPage == 'recapitulatif'">
      <div class="row">
        <div class="col-md-12">
          <div class="border rounded-3 p-2">
            <div class="mb-3 row">
              <div class="fw-semibold">Adresse email</div>
              <div class="col-md-6">
                <span class="">{{ email }}</span>
              </div>
              <div class="col-md-6 text-end">
                <button
                  type="button"
                  class="btn btn-light"
                  (click)="gotoPage('infos-demande', $event)"
                >
                  Modifier
                </button>
              </div>
            </div>

            <div class="mb-3 row">
              <div class="fw-semibold">Catégorie de permis</div>
              <div class="col-md-6">
                <span class="text-uppercase"
                  >Permis {{ selectPermisSelected(categorypermis) }}</span
                >
              </div>
              <div class="col-md-6 text-end">
                <button
                  type="button"
                  class="btn btn-light"
                  (click)="gotoPage('infos-demande', $event)"
                >
                  Modifier
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-6 mt-2">
          <button class="btn btn-between btn-primary" (click)="payment()">
            Payer et Soumettre <app-icon icon="arrow-right"></app-icon>
          </button>
        </div>
      </div>
    </div>

    <!-- paiement -->
    <div class="card-body p-2" *ngIf="currentPage == 'paiement'">
      <div class="row h-100">
        <div class="col h-100">
          <h4 class="mt-3">Paiement effectué avec succès</h4>
          <div class="border rounded-3 p-2">
            <div class="row">
              <div class="col">
                <div class="mb-3">
                  <div class="fw-semibold">NPI</div>
                  <span class="text-uppercase">
                    {{ candidat?.npi }}
                  </span>
                </div>
                <div class="mb-3">
                  <div class="fw-semibold">Nom</div>
                  <span class="text-uppercase">
                    {{ candidat?.nom }}
                  </span>
                </div>
                <div class="mb-3">
                  <div class="fw-semibold">Prénoms</div>
                  <span class="text-uppercase">
                    {{ candidat?.prenoms }}
                  </span>
                </div>
              </div>
              <div class="col">
                <div class="mb-3">
                  <div class="fw-semibold">Montant payé</div>
                  <span class="text-uppercase">
                    {{ montant_payer }}
                  </span>
                </div>

                <div class="mb-3">
                  <div class="fw-semibold">Numéro de paiement</div>
                  <span class="text-uppercase">
                    {{ phone_payment }}
                  </span>
                </div>
                <div class="mb-3">
                  <div class="fw-semibold">Date de paiement</div>
                  <span class="text-uppercase">
                    {{ date_payment | date : "dd MMMM yyyy" : "" : "fr" }}
                  </span>
                </div>
              </div>
            </div>
          </div>
          <p class="my-3">
            <a
              type="button"
              href="{{ download_url }}"
              class="btn btn-outline-primary w-100"
              target="_blank"
              >Télécharger la facture</a
            >
          </p>

          <p
            class="my-3"
            *ngIf="messageDownloadPermis"
            [innerHTML]="messageDownloadPermis"
          ></p>
        </div>
      </div>
    </div>
  </div>
</div>
