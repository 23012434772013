<app-blue-aside>
  <app-blue-aside-left>
    <div class="col-md-8">
      <img src="assets/images/login-left.png" alt="" class="img-fluid" />
    </div>
    <div class="login-desc h4 text-center my-3">
      {{ "Driver's License Management System in Benin" | translate }}
    </div>
  </app-blue-aside-left>
  <app-blue-aside-right col="-md-10">
    <app-inscription-examen
      *ngIf="serviceSlug === 'inscription-examen'"
    ></app-inscription-examen>

    <app-suivre-mon-dossier
      *ngIf="serviceSlug === 'suivre-dossier'"
    ></app-suivre-mon-dossier>
  </app-blue-aside-right>
</app-blue-aside>
