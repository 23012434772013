<div
  class="d-flex justify-content-end align-items-center align-content-center"
  *ngIf="totalPage > 1"
>
  <div>
    <select
      class="form-select bg-transparent form-select-lg"
      name="pageNumber"
      id="pageNumber"
      [(ngModel)]="pageNumber"
      (change)="paginateOnChange($event.target)"
    >
      <option *ngFor="let page of pages" [value]="page">
        {{ page }} / {{ totalPage }} {{ totalPage > 1 ? "pages" : "page" }}
      </option>
    </select>
  </div>
  <pagination-controls
    (pageChange)="paginate($event)"
    previousLabel=""
    nextLabel=""
    class="mx-3"
  ></pagination-controls>
  <div class="d-flex align-items-center">
    <div class="goto-page">Aller à</div>
    <input
      min="1"
      type="number"
      [(ngModel)]="pageNumber"
      class="form-control bg-transparent ms-2 my-0"
      style="width: 80px"
      [max]="totalPage"
      (change)="paginateOnChange($event.target)"
    />
  </div>
</div>
