<div class="service-details">
  <h3 class="title">Authenticité du permis de conduire</h3>

  <div class="card border mt-4">
    <div class="card-body">
      <div class="bg-light p-3 rounded">
        <div class="row">
          <div class="col-6 col-md mb-3 mb-md-0">
            <app-number-box [number]="1" [filled]="pageActive(1)">
              Infos sur la demande
            </app-number-box>
          </div>

          <div class="col-6 col-md mb-3 mb-md-0">
            <app-number-box [number]="2" [filled]="pageActive(2)">
              Récapitulatif
            </app-number-box>
          </div>
          <div class="col-4 col-md mb-3 mb-md-0">
            <app-number-box [number]="3" [filled]="pageActive(3)"
              >{{ "Payment" | translate }}
            </app-number-box>
          </div>
        </div>
      </div>
    </div>

    <div id="auth-permis-form" class="card-body">
      <ng-container *ngIf="page == 1">
        <div class="row">
          <div class="col-md-6">
            <h6>Numéro du permis de conduire</h6>

            <div class="mb-3">
              <div class="input-styled">
                <input
                  type="text"
                  class="form-control"
                  name="num_permis"
                  [(ngModel)]="form.num_permis"
                  qv-rules="required|minlength:8"
                  qv-messages="Ce champ est obligatoire | Le numéro du permis semble être trop court"
                />
              </div>
              <div qv-feedback="num_permis"></div>
            </div>
          </div>
          <div class="col-md-6">
            <h6>Copie du permis</h6>

            <ng-container *ngIf="!form.permis_file">
              <app-input-file
                accept=".png,.jpg"
                (changeEvent)="onFilePermisPrealableChange($event)"
              ></app-input-file>
            </ng-container>
            <ng-container *ngIf="form.permis_file">
              <app-input-file
                accept=".png,.jpg"
                (changeEvent)="onFilePermisPrealableChange($event)"
                [selectedImage]="asset(form.permis_file)"
                placeholder="Modifier l'image existante"
              ></app-input-file>
            </ng-container>
          </div>
        </div>
        <div class="col-md-6">
          <h6>Adresse e-mail</h6>
          <p class="small text-muted">
            Votre demande sera envoyée sur cette adresse mail
          </p>
          <div class="mb-3">
            <div class="input-styled">
              <input
                type="email"
                class="form-control"
                name="email"
                [(ngModel)]="form.email"
                qv-rules="required|email"
                qv-messages="Ce champ est obligatoire | Ce champ doit être une adresse e-mail valide"
              />
            </div>
            <div qv-feedback="email"></div>
          </div>
        </div>
        <div class="col-md-3">
          <button
            class="btn btn-between btn-primary"
            (click)="gotoPage(2)"
            [disabled]="!formValid || !fileValid"
          >
            Suivant <app-icon icon="arrow-right"></app-icon>
          </button>
        </div>
      </ng-container>

      <ng-container *ngIf="page == 2">
        <div class="row align-items-center mb-3">
          <div class="col-6">
            <h6 class="text-primary">Adresse mail</h6>
            <p>{{ form.email }}</p>
          </div>
          <div class="col-6 text-end">
            <span (click)="gotoPage(1)" role="button">Modifier</span>
          </div>
        </div>

        <div class="row align-items-center mb-3">
          <div class="col-6">
            <h6 class="text-primary">Numéro du permis de conduire</h6>
            <p>{{ form.num_permis }}</p>
          </div>
          <div class="col-6 text-end">
            <span (click)="gotoPage(1)" role="button">Modifier</span>
          </div>
        </div>
        <div class="row mb-3">
          <div class="col-6">
            <div class="col-6" *ngIf="!!copiePermis || !!form.permis_file">
              <h6 class="mb-2">Copie du permis</h6>
              <div class="preview-pdf" (click)="openImageModal()">
                <div class="prev-eye col-md-9 col-xl-7">
                  <div class="overlay"></div>
                  <app-icon icon="eye" (click)="openImageModal()"></app-icon>
                  <img
                    [src]="imageSrc"
                    alt="Aperçu de l'image"
                    class="img-fluid"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="col-6 text-end">
            <span (click)="gotoPage(1)" role="button">Modifier</span>
          </div>
        </div>

        <div class="d-flex justify-content-between">
          <p>
            <button
              class="btn btn-between btn-outline-primary"
              (click)="gotoPage(1)"
            >
              <app-icon icon="arrow-left"></app-icon> Précédent
            </button>
          </p>
          <p>
            <button
              class="btn btn-between btn-primary"
              [disabled]="!formValid || !fileValid || !!payment"
              (click)="save()"
            >
              <ng-container *ngIf="!rejetId">
                Payer et soumettre <app-icon icon="arrow-right"></app-icon>
              </ng-container>

              <ng-container *ngIf="rejetId">
                Soumettre <app-icon icon="arrow-right"></app-icon>
              </ng-container>
            </button>
          </p>
        </div>
      </ng-container>

      <ng-container *ngIf="page == 3">
        <ng-container *ngIf="!rejetId">
          <div class="card border mb-3 alert alert-primary">
            <div class="card-body p-2">
              <div class="row h-100">
                <div class="col h-100">
                  <h4 class="mt-3">Paiement effectué avec succès</h4>
                  <div class="border rounded-3 p-2">
                    <div class="row">
                      <div class="col">
                        <div class="mb-3">
                          <div class="fw-semibold">NPI</div>
                          <span class="text-uppercase">
                            {{ candidat?.npi }}
                          </span>
                        </div>
                        <div class="mb-3">
                          <div class="fw-semibold">Nom</div>
                          <span class="text-uppercase">
                            {{ candidat?.nom }}
                          </span>
                        </div>
                        <div class="mb-3">
                          <div class="fw-semibold">Prénoms</div>
                          <span class="text-uppercase">
                            {{ candidat?.prenoms }}
                          </span>
                        </div>
                      </div>
                      <div class="col">
                        <div class="mb-3">
                          <div class="fw-semibold">Montant payé</div>
                          <span class="text-uppercase">
                            {{ transaction?.amount }}
                          </span>
                        </div>

                        <div class="mb-3">
                          <div class="fw-semibold">Date de paiement</div>
                          <span class="text-uppercase">
                            {{
                              transaction?.date_payment
                                | date : "dd MMMM yyyy" : "" : "fr"
                            }}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <p class="my-3" *ngIf="download_url">
                    <a
                      type="button"
                      [href]="download_url"
                      class="btn btn-outline-primary w-100"
                      target="_blank"
                      >Télécharger la facture</a
                    >
                  </p>
                </div>
              </div>
            </div>
          </div>
        </ng-container>

        <ng-container *ngIf="rejetId">
          <div class="alert alert-primary text-center" role="alert">
            <h6>
              Votre correction a été soumise avec succès <br />
              et en attente de validation par l'ANaTT.
            </h6>
            <p class="mt-3">
              <a href="/services/suivre-dossier" class="btn btn-primary">
                Suivre ma demande
              </a>
            </p>
          </div>
        </ng-container>
      </ng-container>
    </div>
  </div>
</div>
<ng-container *ngIf="payment">
  <app-fedapay-box
    [transaction]="payment"
    (onCompleted)="getTransaction($event)"
  />
</ng-container>
<div
  class="modal fade"
  id="openImageModal"
  tabindex="-1"
  role="dialog"
  aria-hidden="true"
>
  <div
    class="modal-dialog modal-dialog-scrollable modal-dialog-centered"
    role="document"
  >
    <div class="modal-content">
      <div class="modal-body">
        <img [src]="imageSrc" class="img-fluid rounded-top" />
      </div>
    </div>
  </div>
</div>
