<div class="blue-side-container">
  <div class="row h-100 m-0">
    <div class="col-md-5 d-none d-md-block bg-primary px-5 order-1 order-md-0">
      <ng-content select="app-blue-aside-left"></ng-content>
    </div>
    <div class="col-md-7 scrollable-content">
      <div class="d-flex flex-column h-100 justify-content-between">
        <div
          class="row header align-items-center justify-content-between bg-light"
        >
          <div class="col-8 col-sm-6 col-md-5">
            <a href=""
              ><img src="assets/images/logo.png" alt="" class="img-fluid"
            /></a>
          </div>

          <div class="col-4 mb-3 mb-md-0">
            <div
              class="d-flex justify-content-end align-items-center mt-3 mt-lg-0"
            >
              <div class="d-flex justify-content-end flex-column">
                <button
                  class="btn btn-outline-primary fw-bolder border-2 rounded-3"
                  (click)="toggleSidebar()"
                >
                  <app-icon
                    icon="list"
                    [size]="30"
                    classe="fw-bolder"
                  ></app-icon>
                </button>
              </div>
            </div>
            <!--Mobile-->
          </div>
        </div>
        <div class="py-3">
          <ng-content></ng-content>
        </div>

        <div class="row footer bg-light text-center py-2">
          <h6>{{ "Republic of Benin" | translate }}</h6>
          <p>
            {{
              "Ministry of Environment and Transport Responsible for Sustainable Development"
                | translate
            }}
          </p>
          <p>{{ "All Rights Reserved 2023" | translate }}</p>
        </div>
      </div>
    </div>
  </div>
</div>
<app-sidebar [auth]="auth" [toggle]="_toggleSidebar"></app-sidebar>
