import { Component } from '@angular/core';

@Component({
  selector: 'app-inscription-examen',
  templateUrl: './inscription-examen.component.html',
  styleUrls: ['./inscription-examen.component.scss']
})
export class InscriptionExamenComponent {

}
