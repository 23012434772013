<div class="position-relative">
  <div class="input-grouped border px-2">
    <app-icon icon="search" [size]="16"></app-icon>
    <input
      type="search"
      name="search"
      [placeholder]="message"
      class="form-control border-0 bg-transparent pt-2"
      (input)="onInput()"
      [(ngModel)]="search"
    />
    <app-icon icon="tune" [size]="16"></app-icon>
  </div>
  <div
    class="spinner-border spinner-border-sm mt-1"
    role="status"
    *ngIf="is_loading"
  >
    <span class="visually-hidden">Loading...</span>
  </div>
  <div class="search-result">
    <ng-content></ng-content>
  </div>
</div>
