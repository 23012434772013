export const environment = {
  endpoints: {
    candidat: 'https://formationsigpcb.anatt.bj:8081/api/anatt-candidat',
    asset: 'https://formationsigpcb.anatt.bj:8081/storage/',
  },
  api_key: '_4N46y6P3cGg0#^6^',

  candidat: {
    asset: 'https://formationsigpcb.anatt.bj:8081/storage/',
  },
  fedapay: {
    sandbox: 'sandbox',
    key: 'pk_sandbox_ofObrQoYYxGo8tBOTZ43eI44',
  },
  recaptcha_key: '6LckbHApAAAAADSr4TB57fvog7faq-L4wkMuRzEU',
  production: false,
};
