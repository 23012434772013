<div class="container-fluid vh-100">
  <div class="row h-100 justify-content-center align-items-center">
    <div class="col-sm-7 col-md-5">
      <div class="card shadow border-0 py-4">
        <div class="card-body">
          <div class="col-8 col-md-8 mx-auto my-3">
            <img src="assets/images/logo.png" class="img-fluid" />
          </div>
          <form quickv id="opt-form">
            <h4 class="text-center my-3">Mot de passe oublié</h4>
            <p>
              Si vous avez oublié votre mot de passe, ne vous inquiétez pas,
              nous sommes là pour vous aider à récupérer l'accès à votre compte.
              Pour cela, veuillez renseigner votre adresse e-mail associée à
              votre compte dans le champ ci-dessous.
            </p>

            <div class="mb-3">
              <input
                type="text"
                qv-rules="required|email"
                qv-messages="L'adresse e-mail est requise | Adresse e-mail incorrecte"
                qv-valid-class="is-valid"
                class="form-control"
                name="email"
                placeholder="Entrer l'adresse e-mail"
                [(ngModel)]="email"
              />
            </div>
            <div qv-feedback="email" class="my-3"></div>
            <button
              (click)="send($event)"
              type="submit"
              qv-submit
              class="btn btn-primary btn-lg"
              [disabled]="isloading"
            >
              Envoyer
            </button>
            <br />
            <span *ngIf="isloading">
              <span
                class="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
              ></span>
              <span class="visually-hidden">Loading...</span>
            </span>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
