<ng-container *ngIf="prestation">
  <div class="service-card d-none d-md-flex" role="button">
    <div class="img-box mb-3">
      <ng-container
        *ngIf="prestation.slug === 'inscription-examen'; else otherImage"
      >
        <img
          [src]="prestation.image"
          [alt]="prestation.title"
          (click)="demande(prestation.slug)"
        />
      </ng-container>
      <ng-template #otherImage>
        <a role="button" (click)="goto(prestation)">
          <img [src]="prestation.image" [alt]="prestation.title" />
        </a>
      </ng-template>
    </div>
    <div
      class="service-title"
      [innerHTML]="prestation.title"
      *ngIf="prestation.slug === 'inscription-examen'; else otherLink"
      (click)="demande(prestation.slug)"
    ></div>
    <ng-template #otherLink>
      <div
        class="service-title"
        [innerHTML]="prestation.title"
        (click)="goto(prestation)"
      ></div>
    </ng-template>
    <div class="mt-3" *ngIf="prestation.active">
      <ng-content select=".d-md-block"></ng-content>
    </div>
  </div>

  <div
    class="mobile-card d-md-none align-item-center border border-primary rounded"
  >
    <div class="row align-items-center justify-content-between h-100">
      <div class="col-2 h-100">
        <div class="py-2 p-2 w-100">
          <ng-container
            *ngIf="prestation.slug === 'inscription-examen'; else otherImage"
          >
            <img
              [src]="prestation.image"
              [alt]="prestation.title"
              (click)="demande(prestation.slug)"
            />
          </ng-container>
          <ng-template #otherImage>
            <a role="button" (click)="goto(prestation)">
              <img [src]="prestation.image" [alt]="prestation.title" />
            </a>
          </ng-template>
        </div>
      </div>
      <div class="col-7 h-100">
        <div class="py-2 w-100">
          <div
            class="service-title"
            [innerHTML]="prestation.title"
            *ngIf="prestation.slug === 'inscription-examen'; else otherLink"
            (click)="demande(prestation.slug)"
          ></div>

          <ng-template #otherLink>
            <div
              class="service-title"
              [innerHTML]="prestation.title"
              (click)="goto(prestation)"
            ></div>
          </ng-template>
        </div>
      </div>
      <div class="col-3 h-100" *ngIf="prestation.active">
        <ng-content select=".d-md-none "></ng-content>
      </div>
    </div>
  </div>
</ng-container>
