import { Component } from '@angular/core';

@Component({
  selector: 'app-blue-aside-left',
  templateUrl: './blue-aside-left.component.html',
  styleUrls: ['./blue-aside-left.component.scss']
})
export class BlueAsideLeftComponent {

}
