<div class="container-fluid vh-100">
  <div class="row h-100 justify-content-center align-items-center">
    <div class="col-sm-7 col-md-5">
      <div class="card shadow border-0 py-3">
        <div class="card-body">
          <div class="col-8 col-md-8 mx-auto">
            <img src="assets/images/logo.png" class="img-fluid" />
          </div>
          <form quickv id="reset-password" (input)="confirmPassword()">
            <h4 class="text-center my-3">Mot de passe oublié</h4>
            <p class="my-3">
              Veuillez renseigner votre adresse e-mail associée à votre compte
              dans le champ ci-dessous.
            </p>

            <div class="mb-3">
              <label for="" class="required">Nouveau mot de passe</label>
              <input
                type="password"
                class="form-control"
                name="password"
                placeholder="Entrez le nouveau mot de passe"
                [(ngModel)]="password"
              />
              <div qv-feedback="password" class="my-3"></div>
              <p class="form-text small text-muted">
                Le mot de passe doit être de
                <strong>8 caractères minimum</strong>,
                <strong>au moins une lettre majuscule</strong>,
                <strong>une lettre minuscule</strong>,
                <strong>un nombre</strong> et au moins un caractère spécial
              </p>
            </div>

            <div class="mb-3">
              <label for="" class="required">Confirmer mot de passe</label>
              <input
                type="password"
                class="form-control"
                name="password_confirmation"
                placeholder="Confirmer le mot de passe"
                [(ngModel)]="confirm_password"
              />
              <div qv-feedback="password_confirmation" class="my-3"></div>
            </div>

            <button
              (click)="send($event)"
              type="submit"
              class="btn btn-primary btn-lg"
              [disabled]="isloading || !confirmPassword"
            >
              Envoyer
            </button>
            <br />
            <span *ngIf="isloading">
              <span
                class="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
              ></span>
              <span class="visually-hidden">Loading...</span>
            </span>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
