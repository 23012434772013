<app-icon
  icon="trash3"
  (click)="open()"
  [size]="16"
  classe="text-danger"
  [ngClass]="showLoader ? 'disabled' : ''"
></app-icon>
<span
  class="spinner-border spinner-border-sm"
  role="status"
  aria-hidden="true"
  *ngIf="showLoader"
></span>
<div class="delete-alert" *ngIf="show">
  <div class="d-flex justify-content-between my-1">
    <span class="fw-semibold" style="font-size: 12px">Attention</span>
    <app-icon
      icon="x"
      [size]="14"
      classe="text-danger"
      (click)="cancel()"
    ></app-icon>
  </div>
  <div>
    <button
      class="btn btn-sm btn-success me-1"
      title="Continuer"
      (click)="confirmDelete()"
    >
      <app-icon icon="check-circle"></app-icon>
    </button>
    <button class="btn btn-sm btn-danger" title="Annuler" (click)="cancel()">
      <app-icon icon="x"></app-icon>
    </button>
  </div>
</div>
