<div class="row permis-list" *ngIf="permis">
  <div
    class="col-4 col-sm-3 col-lg-2 mb-3 permis-item"
    *ngFor="let pm of permis"
  >
    <app-permis
      [permis]="pm.name"
      [checked]="isSelected(pm.id)"
      (click)="onSelected(pm.id)"
    ></app-permis>
  </div>
</div>
